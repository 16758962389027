import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import TypeIntegrationSelect from "../TypeIntegrationSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const IntegrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  slug: Yup.string().required("Required"),
});

const IntegrationModal = ({ open, onClose, integrationId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    slug: "",
    interval: "1000",
    typebotMinDelay: "3000",
    typebotMaxDelay: "5000",
  };

  const [integration, setIntegration] = useState(initialState);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    (async () => {
      if (!integrationId) return;
      try {
        const { data } = await api.get(`/integrations/${integrationId}`);
        setIntegration(data);
        setSelectedType(data.type);
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setIntegration({
        name: "",
        slug: "",
        interval: "1000",
        typebotMinDelay: "3000",
        typebotMaxDelay: "5000",
      });
    };
  }, [integrationId, open]);

  const handleClose = () => {
    onClose();
    setSelectedType("");
    setIntegration(initialState);
  };

  const handleSaveIntegration = async (values) => {
    const integrationData = {
      ...values,
      type: selectedType,
    };

    try {
      if (integrationId) {
        await api.put(`/integrations/${integrationId}`, integrationData);
      } else {
        await api.post("/integrations", integrationData);
      }
      toast.success("Integration saved successfully");
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        fullWidth
        onClose={() => handleClose()}
        scroll="paper"
      >
        <DialogTitle>
          {integrationId
            ? `${i18n.t("integrationModal.title.edit")}`
            : `${i18n.t("integrationModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={integration}
          enableReinitialize={true}
          validationSchema={IntegrationSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveIntegration(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <TypeIntegrationSelect
                  selectedITypeIntegrationId={selectedType}
                  onChange={(selectedType) => setSelectedType(selectedType)}
                />

                <Field
                  as={TextField}
                  label={i18n.t("integrationModal.form.name")}
                  autoFocus
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  className={classes.textField}
                />

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    as={TextField}
                    label={i18n.t("integrationModal.form.slug")}
                    name="slug"
                    error={touched.slug && Boolean(errors.slug)}
                    helperText={touched.slug && errors.slug}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    className={classes.textField}
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("integrationModal.form.interval")}
                    name="interval"
                    error={touched.interval && Boolean(errors.interval)}
                    helperText={touched.interval && errors.interval}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    className={classes.textField}
                  />
                </div>

                {selectedType === "typebot" && (
                  <>
                    <div style={{ borderTop: "1px solid #ccc", marginTop: "1rem" }} />
                    <p>
                      {i18n.t("integrationModal.form.typebotDelay")}
                    </p>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Field
                        as={TextField}
                        label={i18n.t("integrationModal.form.typebotMinDelay")}
                        name="typebotMinDelay"
                        error={touched.typebotMinDelay && Boolean(errors.typebotMinDelay)}
                        helperText={touched.typebotMinDelay && errors.typebotMinDelay}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={classes.textField}
                      />

                      <Field
                        as={TextField}
                        label={i18n.t("integrationModal.form.typebotMaxDelay")}
                        name="typebotMaxDelay"
                        error={touched.typebotMaxDelay && Boolean(errors.typebotMaxDelay)}
                        helperText={touched.typebotMaxDelay && errors.typebotMaxDelay}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={classes.textField}
                      />
                    </div>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("integrationModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {integrationId
                    ? `${i18n.t("integrationModal.buttons.okEdit")}`
                    : `${i18n.t("integrationModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default IntegrationModal;
