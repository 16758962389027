import React, { useState, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import ColorPicker from "../ColorPicker";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Colorize } from "@material-ui/icons";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { getChatBotStatus } from "../../config";
import TypeBotSelect from "../TypeBotSelect";
import TimeAutoCloseTicket from "../TimeAutoCloseSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const initialState = useMemo(() => ({
    name: "",
    greetingMessage: "",
    color: "#000000",
    farewellMessage: "",
    closedMessage: "",
    breakMessage: "",
    isDefault: false,
    isBot: false,
    rejectCalls: false,
    rejectCallMessage: "",
  }), []);
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [selectedTypeBotId, setSelectedTypeBotId] = useState("");
  const [selectedTimeAutoCloseTicket, setSelectedTimeAutoCloseTicket] =


    useState(0);
  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [, setColor] = useState(initialState.color);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) {
        // reset form
        setWhatsApp(initialState);
        setSelectedQueueIds([]);
        setSelectedTypeBotId("");
        return;
      }

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}`);
        // Convert null values to empty strings for form fields
        const formattedData = {
          ...data,
          name: data.name || "",
          color: data.color || "#000000",
          greetingMessage: data.greetingMessage || "",
          farewellMessage: data.farewellMessage || "",
          closedMessage: data.closedMessage || "",
          breakMessage: data.breakMessage || "",
          isDefault: data.isDefault || false,
          isBot: data.isBot || false,
          rejectCalls: data.rejectCalls || false,
          rejectCallMessage: data.rejectCallMessage || "",
        };
        setWhatsApp(formattedData);


        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds || []);

        const typeBotId = data.typeBotId ? String(data.typeBotId) : "";
        setSelectedTypeBotId(typeBotId || "");

        setSelectedTimeAutoCloseTicket(data.autoCloseTicket || 0);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [initialState, whatsAppId]);

  const handleSaveWhatsApp = async (values) => {
    const whatsappData = {
      ...values,
      queueIds: selectedQueueIds,
      typeBotId: selectedTypeBotId === "" ? null : selectedTypeBotId,
      autoCloseTicket: selectedTimeAutoCloseTicket,
    };

    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
  };

  const handleCloseColorPicker = () => {
    setColorPickerModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppId
            ? i18n.t("whatsappModal.title.edit")
            : i18n.t("whatsappModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.name")}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.color")}
                    name="color"
                    id="color"
                    onFocus={() => {
                      setColorPickerModalOpen(true);
                    }}
                    error={touched.color && Boolean(errors.color)}
                    helperText={touched.color && errors.color}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div
                            style={{ backgroundColor: values.color }}
                            className={classes.colorAdorment}
                          ></div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton
                          size="small"
                          color="default"
                          onClick={() => setColorPickerModalOpen(true)}
                        >
                          <Colorize />
                        </IconButton>
                      ),
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                  <ColorPicker
                    open={colorPickerModalOpen}
                    handleClose={handleCloseColorPicker}
                    onChange={(color) => {
                      values.color = color;
                      setColor(() => {
                        return { ...values, color };
                      });
                    }}
                  />

                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isDefault"
                        checked={values.isDefault}
                      />
                    }
                    label={i18n.t("whatsappModal.form.default")}
                  />

                  {/* <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="rejectCalls"
                        checked={values.rejectCalls}
                      />
                    }
                    label={i18n.t("whatsappModal.form.rejectCalls")}
                  /> */}

                  {getChatBotStatus() ? (
                    <FormControlLabel
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="isBot"
                          checked={values.isBot}
                        />
                      }
                      label={i18n.t("whatsappModal.form.isBot")}
                    />
                  ) : null}
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.greetingMessage")}
                    type="greetingMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="greetingMessage"
                    error={
                      touched.greetingMessage && Boolean(errors.greetingMessage)
                    }
                    helperText={
                      touched.greetingMessage && errors.greetingMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.farewellMessage")}
                    type="farewellMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="farewellMessage"
                    error={
                      touched.farewellMessage && Boolean(errors.farewellMessage)
                    }
                    helperText={
                      touched.farewellMessage && errors.farewellMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.breakMessage")}
                    type="breakMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="breakMessage"
                    error={touched.breakMessage && Boolean(errors.breakMessage)}
                    helperText={touched.breakMessage && errors.breakMessage}
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.closedMessage")}
                    type="closedMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="closedMessage"
                    error={
                      touched.closedMessage && Boolean(errors.closedMessage)
                    }
                    helperText={touched.closedMessage && errors.closedMessage}
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                {values.rejectCalls && (
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("whatsappModal.form.rejectCallMessage")}
                      type="rejectCallMessage"
                      multiline
                      minRows={5}
                      fullWidth
                      name="rejectCallMessage"
                      error={touched.rejectCallMessage && Boolean(errors.rejectCallMessage)}
                      helperText={touched.rejectCallMessage && errors.rejectCallMessage}
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                )}

                {values.isBot && (
                  <>
                    <TypeBotSelect
                      selectedTypeBotId={selectedTypeBotId}
                      onChange={(selectedId) =>
                        setSelectedTypeBotId(selectedId)
                      }
                    />
                  </>
                )}
                <QueueSelect
                  selectedQueueIds={selectedQueueIds}
                  onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                />
                <TimeAutoCloseTicket
                  selectedTimeAutoCloseTicket={selectedTimeAutoCloseTicket}
                  onChange={(value) => setSelectedTimeAutoCloseTicket(value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("whatsappModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {whatsAppId
                    ? i18n.t("whatsappModal.buttons.okEdit")
                    : i18n.t("whatsappModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
