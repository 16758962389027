import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import {
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { parseISO, format, subDays } from "date-fns";
import SearchIcon from "@material-ui/icons/Search";
import { MoreVert, GetApp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainContainer: {},
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    marginTop: theme.spacing(4),
  },
  mainHeaderButtonsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      "& > *": {
        marginBottom: theme.spacing(1),
        marginLeft: 0,
      },
    },
  },
}));

const Reports = () => {
  const classes = useStyles();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchId, setSearchId] = useState("");
  const [startDate, setStartDate] = useState(format(subDays(new Date(), 1), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchReports = async () => {
        try {
          const { data } = await api.get("/reports", {
            params: {
              pageNumber,
              pageSize,
              searchId,
              startDate,
              endDate,
            },
          });

          // Filter out entries where both ticketLog and messageLog are null
          const filteredReports = data.reports.filter(
            report => report.ticketLog !== null || report.messageLog !== null
          );
          
          setReports(filteredReports);
          setTotalCount(data.count);
          setLoading(false);
        } catch (error) {
          toastError(error);
          setLoading(false);
        }
      };
      fetchReports();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [pageNumber, pageSize, searchId, startDate, endDate]);

  const handlePageChange = (event, newPage) => {
    setLoading(true);
    setPageNumber(newPage + 1);
  };

  const handlePageSizeChange = (event) => {
    setLoading(true);
    setPageSize(event.target.value);
  };

  const handleSearch = (event) => {
    setSearchId(event.target.value.toLowerCase());
  };

  const handleDateChange = (event) => {
    if (event.target.id === "startDate") {
      setStartDate(event.target.value);
    } else {
      setEndDate(event.target.value);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadReport = async () => {
    try {
      await api
        .get("/reports/download", {
          params: { searchId, startDate, endDate },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `report-${format(new Date(), "yyyy-MM-dd HH:mm:ss")}.csv`
          );
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      toastError(error);
    }
  };

  const handleDownloadChatReport = async () => {
    try {
      await api
        .get("/reports/download/chat", {
          params: { searchId, startDate, endDate },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `chat-report-${format(new Date(), "yyyy-MM-dd HH:mm:ss")}.csv`
          );
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <MainHeader>
        <Title>{i18n.t("reports.title")}</Title>
        <MainHeaderButtonsWrapper className={classes.mainHeaderButtonsWrapper}>
          <TextField
            label="Search by Ticket ID"
            placeholder={i18n.t("quickAnswers.searchPlaceholder")}
            type="search"
            value={searchId}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="startDate"
            label="Start Date"
            type="date"
            value={startDate}
            className={classes.textField}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          -
          <TextField
            id="endDate"
            label="End Date"
            type="date"
            value={endDate}
            className={classes.textField}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {searchId || startDate || endDate ? (
            <Button
              color="secondary"
              onClick={() => {
                setSearchId("");
                setStartDate(format(subDays(new Date(), 1), 'yyyy-MM-dd'));
                setEndDate(format(new Date(), 'yyyy-MM-dd'));
              }}
            >
              Clear
            </Button>
          ) : null}
          
          <IconButton color="primary" onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => { handleDownloadReport(); handleMenuClose(); }}>
              <GetApp style={{ marginRight: 8 }} />
              Download Report
            </MenuItem>
            <MenuItem onClick={() => { handleDownloadChatReport(); handleMenuClose(); }}>
              <GetApp style={{ marginRight: 8 }} />
              Download Chat Report
            </MenuItem>
          </Menu>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper elevation={0} className={classes.mainPaper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.t("reports.tableHeaders.chatId")}</TableCell>
              <TableCell>{i18n.t("reports.tableHeaders.createdAt")}</TableCell>
              <TableCell>{i18n.t("reports.tableHeaders.progress")}</TableCell>
              <TableCell>{i18n.t("reports.tableHeaders.user")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {!loading ? (
                <>
                  {reports && reports.length
                    ? reports.map((report, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {report?.ticketLog && !report?.ticketLog?.ticketId ? (
                              <>
                                # {report?.ticketLog?.oldTicketId || report?.messageLog?.ticketId}
                                <br />
                                <Chip
                                  label="Deleted"
                                  size="small"
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                />
                              </>
                            ) : (
                              <># {report?.ticketLog?.ticketId || report?.messageLog?.ticketId}</>
                            )}
                          </TableCell>
                          <TableCell>
                            {report?.ticketLog?.createdAt || report?.messageLog?.createdAt ? (
                              format(
                                parseISO(report?.ticketLog?.createdAt || report?.messageLog?.createdAt),
                                "dd/MM/yyyy HH:mm:ss a"
                              )
                            ) : (
                              "No date"
                            )}
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={i18n.t(report?.ticketLog?.status || report?.messageLog?.status)}
                              color={"info"}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              style={{ textTransform: "capitalize" }}
                            >
                              {report?.ticketLog?.userName || report?.messageLog?.userName || "No user"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))
                    : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            {i18n.t("reports.noData")}
                          </TableCell>
                        </TableRow>
                    )}
                </>
              ) : (
                <TableRowSkeleton columns={4} />
              )}
            </>
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={pageNumber - 1}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onRowsPerPageChange={handlePageSizeChange}
        />
      </Paper>
    </MainContainer>
  );
};

export default Reports;
