import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useState, useEffect } from "react";
import { i18n } from "../../translate/i18n";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const ForwardMessageModal = ({ open, onClose, message }) => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  useEffect(() => {
    if (!open || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, open]);

  const handleForwardMessage = async () => {
    try {
        await api.post(`/messages/${message.id}/forward`, {
            contactId: selectedContact.id
        });
        onClose();
    } catch (err) {
        toastError(err);
    }
  };

  const renderOption = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } 
  };

  const renderOptionLabel = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };

  const createAddContactOption = (options, params) => {
    return options;
  };

  const handleSelectOption = (e, newValue) => {
    if (newValue?.number) {
      setSelectedContact(newValue);
    }
  };

  const handleClose = () => {
    setSelectedContact(null);
    setSearchParam("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {i18n.t("forwardMessageModal.title")}
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 24 }}>
        <div style={{ marginBottom: 24 }}>
          <Autocomplete
            options={options}
            loading={loading}
            fullWidth
            clearOnBlur
            autoHighlight
            freeSolo
            clearOnEscape
            getOptionLabel={renderOptionLabel}
            renderOption={renderOption}
            filterOptions={createAddContactOption}
            onChange={(e, newValue) => handleSelectOption(e, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={i18n.t("newTicketModal.fieldLabel")}
                variant="outlined"
                autoFocus
                fullWidth
                onChange={(e) => setSearchParam(e.target.value)}
                onKeyPress={(e) => {
                  if (loading || !selectedContact) return;
                  else if (e.key === "Enter") {
                    handleForwardMessage();
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
          <Button 
            onClick={handleClose}
            variant="outlined"
            color="secondary"
          >
            {i18n.t("forwardMessageModal.cancel")}
          </Button>
          <Button 
            onClick={handleForwardMessage}
            variant="contained"
            color="primary"
            disabled={loading || !selectedContact}
          >
            {i18n.t("forwardMessageModal.forward")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ForwardMessageModal;
