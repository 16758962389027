import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import profilePicUrl from "../../utils/profile-pic-url";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import {
  PersonAdd,
  ImportExport,
  OfflineBolt,
  Tune,
  FilterList,
  WhatsApp,
  Chat,
} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import LabelIcon from "@material-ui/icons/Label";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import TablePagination from "@material-ui/core/TablePagination";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import {
  Badge,
  withStyles,
  ButtonGroup,
  Chip,
  Grid,
  MenuItem,
  Popper,
  Tooltip,
  Typography,
  Menu,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import LabelAssignContactModal from "../../components/LabelAssignContactModal";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import ContactFilterModal from "../../components/ContactFilterModal";
import ContactImportExportModal from "../../components/ContactImportExportModal";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    return contacts;
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  mainHeaderSub: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const StyleBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "red",
    color: "red",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    animation: "$pulse 1.5s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
}))(Badge);

const Contacts = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [selectedContactIds, setSelectedContactIds] = useState([]);

  const [openImportExportModalOpen, setOpenImportExportModalOpen] =
    useState(false);

  const [confirmBulkDeleteOpen, setConfirmBulkDeleteOpen] = useState(false);
  const [openLabelModal, setOpenLabelModal] = useState(false);
  const [selectedContactLabel, setSelectedContactLabel] = useState(null);

  const [openToggle, setOpenToggle] = useState(false);
  const anchorRef = useRef(null);
  const [filters, setFilters] = useState({
    labels: [],
    contactType: "all",
    sort: "latest",
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [labelList, setLabelList] = useState([]);
  const [anchorEl, setAnchorEl] = useState({});

  const { whatsApps: connections, loading: loadingConnections } =
    useContext(WhatsAppsContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedLabels = urlParams.getAll("label");
    const sortOption = urlParams.get("sort") || "latest";
    const contactType = urlParams.get("contactType") || "all";

    setFilters({ labels: selectedLabels, sort: sortOption, contactType });
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/labels");
        setLabelList(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: {
              searchParam,
              pageNumber,
              pageSize,
              ...filters,
            },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setTotalCount(data.count);
          setSelectedContactIds([]);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, pageSize, filters]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("contact", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({
          type: "UPDATE_CONTACTS",
          payload: data.contact,
        });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [searchParam]);

  const handleCheckboxChange = (contactId) => {
    if (selectedContactIds.includes(contactId)) {
      setSelectedContactIds(
        selectedContactIds.filter((id) => id !== contactId)
      );
    } else {
      setSelectedContactIds([...selectedContactIds, contactId]);
    }
  };

  const handleCheckboxSelectAll = () => {
    if (selectedContactIds.length) {
      setSelectedContactIds([]);
    } else {
      setSelectedContactIds(
        contacts
          .filter((contact) => !(contact.ticketCount > 0))
          .map((contact) => contact.id)
      );
    }
  };

  const handleBulkDelete = async () => {
    if (!selectedContactIds.length) {
      toast.error(i18n.t("contacts.toasts.noContactSelected"));
      return;
    }

    setLoading(true);

    try {
      for (const contactId of selectedContactIds) {
        await api.delete(`/contacts/${contactId}`);
      }
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }

    setSelectedContactIds([]);
    setSearchParam("");
    setPageNumber(1);

    history.go(0);

    setLoading(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleOpenImportFile = () => {
    setOpenImportExportModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleSaveTicket = async (contactId, channelId) => {
    if (!contactId) return;
    setLoading(true);
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user?.id,
        status: "open",
        channelId: channelId,
      });
      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handlePageChange = (event, newPage) => {
    setLoading(true);
    setPageNumber(newPage + 1);
  };

  const handlePageSizeChange = (event) => {
    setLoading(true);
    setPageSize(event.target.value);
  };

  const handleAddLabel = (contact) => {
    setOpenLabelModal(true);
    setSelectedContactLabel(contact);
  };

  const handleSortBy = (sortOption) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, sort: sortOption };
      updateURL(newFilters);
      return newFilters;
    });
  };

  const updateURL = (newFilters) => {
    const currentUrlParams = new URLSearchParams(window.location.search);

    if (newFilters.labels.length === 0) {
      currentUrlParams.delete("label");
    } else {
      currentUrlParams.delete("label");
      newFilters.labels.forEach((id) => {
        currentUrlParams.append("label", id.toString());
      });
    }

    currentUrlParams.set("sort", newFilters.sort);
    currentUrlParams.set("contactType", newFilters.contactType);

    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  const handleCloseToggel = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenToggle(false);
  };

  const handleMenuOpen = (event, contactId) => {
    setAnchorEl({ [contactId]: event.currentTarget });
    setSelectedContactId(contactId);
  };

  const handleMenuClose = () => {
    setAnchorEl({});
    setSelectedContactId(null);
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactFilterModal
        open={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
        updateFilters={(newFilters) => {
          setFilters(newFilters);
          updateURL(newFilters);
        }}
        currentFilters={filters}
        labelList={labelList}
      />
      <LabelAssignContactModal
        open={openLabelModal}
        onClose={() => setOpenLabelModal(false)}
        contact={selectedContactLabel}
        updateURL={updateURL}
      />
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      />
      <ContactImportExportModal
        open={openImportExportModalOpen}
        labelList={labelList}
        onClose={() => setOpenImportExportModalOpen(false)}
      />
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${
                deletingContact.name
              }?`
            : null
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact ? handleDeleteContact(deletingContact.id) : null
        }
      >
        {deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : null}
      </ConfirmationModal>
      <ConfirmationModal
        title={i18n.t("contacts.confirmationModal.bulkDeleteTitle")}
        open={confirmBulkDeleteOpen}
        onClose={setConfirmBulkDeleteOpen}
        onConfirm={handleBulkDelete}
      >
        {i18n.t("contacts.confirmationModal.bulkDeleteMessage")}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("contacts.title")}</Title>
        <MainHeaderButtonsWrapper>
          {selectedContactIds.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => setConfirmBulkDeleteOpen(true)}
            >
              {i18n.t("contacts.buttons.bulkDelete")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenImportFile}
            startIcon={<ImportExport />}
          >
            {i18n.t("contacts.buttons.importExportFile")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
            startIcon={<PersonAdd />}
          >
            {i18n.t("contacts.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.mainHeaderSub}
      >
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            margin="dense"
            style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            fullWidth
          />
        </Grid>
        <Grid item>
          <ButtonGroup
            ref={anchorRef}
            color="primary"
            aria-label="outlined button group"
          >
            <Button
              onClick={() => {
                setOpenFilterModal(true);
              }}
            >
              {filters.labels.length > 0 || filters.contactType !== "all" ? (
                <StyleBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  variant="dot"
                >
                  <Tune fontSize="small" style={{ marginRight: "0.5rem" }} />
                </StyleBadge>
              ) : (
                <Tune fontSize="small" style={{ marginRight: "0.5rem" }} />
              )}
              {i18n.t("contacts.buttons.filter")}
            </Button>
            <Button onClick={() => setOpenToggle(!openToggle)}>
              {filters.sort !== "latest" ? (
                <StyleBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  variant="dot"
                >
                  <FilterList
                    fontSize="small"
                    style={{ marginRight: "0.5rem" }}
                  />
                </StyleBadge>
              ) : (
                <FilterList
                  fontSize="small"
                  style={{ marginRight: "0.5rem" }}
                />
              )}
              {i18n.t("contacts.buttons.sortBy")}
            </Button>
          </ButtonGroup>
          <Popper
            open={openToggle}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
            style={{ zIndex: 1 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseToggel}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        selected={filters.sort === "latest"}
                        onClick={() => {
                          handleSortBy("latest");
                          setOpenToggle(false);
                        }}
                      >
                        {i18n.t("contacts.buttons.sortByDate")}
                      </MenuItem>
                      <MenuItem
                        selected={filters.sort === "name"}
                        onClick={() => {
                          handleSortBy("name");
                          setOpenToggle(false);
                        }}
                      >
                        {i18n.t("contacts.buttons.sortByNameAz")}
                      </MenuItem>
                      <MenuItem
                        selected={filters.sort === "name_desc"}
                        onClick={() => {
                          handleSortBy("name_desc");
                          setOpenToggle(false);
                        }}
                      >
                        {i18n.t("contacts.buttons.sortByNameZa")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedContactIds.length > 0}
                  onChange={() => handleCheckboxSelectAll()}
                />
              </TableCell>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.whatsapp")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {!loading ? (
                <>
                  {contacts &&
                    contacts.map((contact) => (
                      <TableRow key={contact.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={contact.ticketCount > 0}
                            checked={selectedContactIds.includes(contact.id)}
                            onChange={() => handleCheckboxChange(contact.id)}
                          />
                        </TableCell>
                        <TableCell style={{ paddingRight: 0 }}>
                          {<Avatar src={profilePicUrl(contact.profilePicUrl)} />}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              gap: 8,
                              flexDirection: "column",
                            }}
                          >
                            <div>
                              <span>
                                {contact.name}{" "}
                                {contact.isGroup && (
                                  <Chip
                                    size="small"
                                    color="default"
                                    style={{ marginLeft: 5 }}
                                    label={i18n.t("contacts.table.group")}
                                  />
                                )}
                              </span>

                              {contact?.nameByWhatsapp ? (
                                <>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    ({contact.nameByWhatsapp})
                                  </Typography>
                                </>
                              ) : null}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: 5,
                                flexWrap: "wrap",
                                width: "100%",
                              }}
                            >
                              {contact.labels && contact.labels.length > 0
                                ? contact.labels.map((label) => (
                                    <Chip
                                      key={label.id}
                                      avatar={
                                        <Avatar
                                          style={{
                                            backgroundColor: label.color,
                                            color: "#FFF",
                                          }}
                                        >
                                          <LabelIcon
                                            style={{ color: label.color }}
                                          />
                                        </Avatar>
                                      }
                                      label={label.name}
                                      size="small"
                                      style={{
                                        borderColor: "grey",
                                        color: "#000",
                                      }}
                                      variant="outlined"
                                      onClick={() => handleAddLabel(contact)}
                                    />
                                  ))
                                : null}

                              <Chip
                                size="small"
                                color="primary"
                                variant="outlined"
                                icon={<AddIcon />}
                                clickable
                                label={i18n.t("contacts.table.label.add")}
                                onClick={() => handleAddLabel(contact)}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center">{contact.number}</TableCell>
                        <TableCell align="center">
                          {loadingConnections ? (
                            <CircularProgress size={20} />
                          ) : connections.length > 1 ? (
                            <>
                              <Tooltip
                                title={i18n.t("contacts.table.startChat")}
                              >
                                <IconButton
                                  aria-label="start chat"
                                  aria-controls="connections-menu"
                                  size="small"
                                  onClick={(event) =>
                                    handleMenuOpen(event, contact.id)
                                  }
                                >
                                  <Chat />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                keepMounted
                                elevation={1}
                                id={`connections-menu-${contact.id}`}
                                anchorEl={anchorEl[contact.id]}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                open={Boolean(anchorEl[contact.id])}
                                onClose={handleMenuClose}
                              >
                                {connections.map((connection) => (
                                  <MenuItem
                                    key={connection.id}
                                    onClick={() => {
                                      handleSaveTicket(
                                        contact.id,
                                        connection.id
                                      );
                                      handleMenuClose();
                                    }}
                                  >
                                    <ListItemIcon>
                                      {connection.channel ===
                                      "whatsappOfficial" ? (
                                        <WhatsApp style={{ color: "green" }} />
                                      ) : (
                                        <OfflineBolt
                                          style={{ color: "orange" }}
                                        />
                                      )}
                                    </ListItemIcon>
                                    <ListItemText primary={connection.name} />
                                  </MenuItem>
                                ))}
                              </Menu>
                            </>
                          ) : connections.length === 1 ? (
                            <Tooltip title={i18n.t("contacts.table.startChat")}>
                              <IconButton
                                aria-label="start chat"
                                aria-controls="connections-menu"
                                size="small"
                                onClick={() =>
                                  handleSaveTicket(
                                    contact.id,
                                    connections[0].id
                                  )
                                }
                              >
                                <Chat />
                              </IconButton>
                            </Tooltip>
                          ) : null}

                          {!contact.isGroup && (
                            <IconButton
                              size="small"
                              onClick={() => hadleEditContact(contact.id)}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          {!(contact.ticketCount > 0) && (
                            <Can
                              role={user.profile}
                              perform="contacts-page:deleteContact"
                              yes={() => (
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    setConfirmOpen(true);
                                    setDeletingContact(contact);
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              )}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              ) : (
                <TableRowSkeleton columns={5} />
              )}
            </>
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={pageNumber - 1}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[20, 50, 100]}
          onRowsPerPageChange={handlePageSizeChange}
        />
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
