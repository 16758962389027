const profilePicUrl = (path) => {
  if (!path) {
    return ""; // Return an empty string if path is null or undefined
  }

  const trimmedPath = path.trim();
  if (trimmedPath.includes("https://")) {
    return trimmedPath;
  }
  return `${process.env.REACT_APP_BACKEND_URL}/public/${trimmedPath}`;
};

export default profilePicUrl;
